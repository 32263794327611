export default {
  ACCORDION: 'Accordion',
  VIDEO: 'Video',
  HEADER: 'Header',
  COLUMNS: 'Columns',
  TIMELINE: 'Timeline',
  TABS: 'Tabs',
  STATEMENT: 'Statement',
  MANAGEMENT: 'Management',
  SLALOM: 'Slalom',
  MAP: 'Map',
  IMAGE_TEXT: 'ImageText',
  TEXT: 'Text',
  IMAGE: 'Image',
  FORM: 'Form',
  SECURE_FILES: 'SecureFiles',
  KEY_CONTACTS: 'KeyContacts'
};
