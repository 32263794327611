<template>
  <el-tooltip :manual="true" v-model="disabled" :content="desc" placement="top" effect="light">
    <p class="card-desc text-left" v-on:mouseover="mouseOver($event)" v-on:mouseleave="mouseLeave($event)">
      {{ desc }}
    </p>
  </el-tooltip>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    desc: {
      type: String
    }
  },
  methods: {
    mouseOver: function(event) {
      let targetEl = event.target;
      if (targetEl.offsetHeight < targetEl.scrollHeight) {
        this.disabled = true;
      }
    },
    mouseLeave: function(event) {
      this.disabled = false;
    }
  },
  data() {
    return {
      disabled: false
    };
  }
};
</script>

<style lang="scss" scoped>
.card-desc {
  // height: 55px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 0;
}
</style>
