<template>
  <div id="same-level-navigation" v-if="items.length > 0">
    <div class="container">
      <h4>{{ 'lp.learnMore' | translate }} > {{ lpModule.title }}</h4>
      <router-link
        class="backToDashboard d-none d-md-block"
        :to="{ name: 'dashboard' }"
        :style="{ color: style.colorPrimary }"
      >
        <i class="material-icons">keyboard_backspace</i>
        {{ 'general.dashboard' | translate }}
      </router-link>
      <div class="d-none d-md-block">
        <div class="flex-row row mt-2">
          <router-link
            tag="div"
            :to="{ name: 'learningPageContent', params: { module: lpModule.id, id: item.id } }"
            v-for="item in items"
            :key="item.id"
            class="mb-3 pointer col-2"
          >
            <div class="card" :style="{ border: selected(item) }">
              <div class="imageContainer">
                <img :alt="item.title" :src="imgSource(item)" />
              </div>
              <div class="card-body">
                <router-link
                  :lang="localeCode.split('-')[0]"
                  :to="{ name: 'learningPageContent', params: { module: lpModule.id, id: item.id } }"
                >
                  <h5 class="font-weight-bold text-left mb-2">{{ item.title }}</h5>
                  <el-tooltip
                    style="max-width: none"
                    effect="light"
                    :content="item.description"
                    placement="top"
                    v-if="item.description.length > 0"
                    popper-class="learning-page-tooltip"
                  >
                    <span class="card-desc">{{ 'lp.hoverForDescription' | translate }}</span>
                  </el-tooltip>
                </router-link>
              </div>
            </div>
          </router-link>
        </div>
      </div>
      <div class="d-block d-md-none d-lg-none d-xl-none">
        <div class="flex-row row">
          <router-link
            tag="div"
            :to="{ name: 'learningPageContent', params: { module: lpModule.id, id: item.id } }"
            v-for="item in items"
            :key="item.id"
            class="mb-3 pointer col-sm-12"
          >
            <div class="card" :style="{ border: selected(item) }">
              <div class="row">
                <div class="col-6 pr-0">
                  <div class="imageContainer">
                    <img :alt="item.title" :src="imgSource(item)" />
                  </div>
                </div>
                <div class="card-body col-6 smallPadding" :lang="localeCode.split('-')[0]">
                  <h5 class="font-weight-bold text-left mb-2">{{ item.title }}</h5>
                  <el-tooltip
                    style="max-width: none"
                    effect="light"
                    :content="item.description"
                    placement="top"
                    v-if="item.description.length > 0"
                    popper-class="learning-page-tooltip"
                  >
                    <span class="card-desc">{{ 'lp.hoverForDescription' | translate }}</span>
                  </el-tooltip>
                </div>
              </div>
            </div>
          </router-link>
          <router-link
            class="backToDashboard pl-3 mb-3"
            :to="{ name: 'dashboard' }"
            :style="{ color: style.colorPrimary }"
          >
            <i class="material-icons">keyboard_backspace</i>
            {{ 'general.dashboard' | translate }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    items: {
      type: Array
    },
    lpModule: {
      type: Object
    }
  },
  methods: {
    created() {
      // FIX IE object fit
      setTimeout(() => {
        var images = document.querySelectorAll('img');
        objectFitImages(images);
      }, 1);
    },
    imgSource(item) {
      if (item.images && item.images.length > 0) {
        let mainImage = item.images.find((image) => image.isMain);
        if (!mainImage) {
          mainImage = item.images[0].url;
        }
        return mainImage.url;
      }
      return require('@lp/images/placeholder.png');
    },
    selected(item) {
      if (item.id === Number(this.$route.params.id)) {
        return '2px solid ' + this.style.learningPageGridOutlineColor;
      }
      return '1px solid rgba(0,0,0,.125)';
    }
  },
  computed: {
    ...mapGetters('settings', ['style']),
    ...mapGetters('learningPages', ['localeCode'])
  }
};
</script>

<style lang="scss">
@import '@shared/styles/all.scss';

// Aspect ratio images for swiper/grid/learn more in learning pages
.imageContainer {
  display: block;
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
  padding: 50% 0 0 0; // Aspect ratio 2:1

  img {
    object-fit: cover;
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  img:not([src]) {
    opacity: 0;
  }
}

#same-level-navigation {
  position: absolute;
  left: 0;
  background-color: #f5f5f5;
  width: 100%;
  padding: 29px;
  // bottom: 30px;

  .card-body a {
    display: block;
  }

  .card-body .card-desc {
    font-weight: bold;
    color: #000;
  }

  .card-body a {
    h5 {
      font-size: 1rem;
    }
    .card-desc {
      font-size: 0.75rem;
    }
    &.router-link-exact-active {
      pointer-events: none;
    }
  }

  .flex-row > div:not(.router-link-exact-active) {
    opacity: 0.8;
    filter: grayscale(1);
  }

  .router-link-exact-active {
    pointer-events: none;
  }

  @media (max-width: 576px) {
    h5 {
      font-size: 0.8rem;
      width: 85%;
      overflow-wrap: break-word;
      word-wrap: break-word;
      -webkit-hyphens: auto;
      -moz-hyphens: auto;
      -ms-hyphens: auto;
      hyphens: auto;
    }
    .card-desc {
      font-size: 0.65rem;
    }
  }

  .flex-row > div {
    display: flex;
    justify-content: center;
  }

  .card {
    width: inherit;
  }

  .card:hover {
    box-shadow: 1px 1px 3px 0px #dddddd;
    background: $hover-color;
  }

  h4 {
    margin-bottom: 19px;
    display: inline-block;
  }

  .card.selected {
    border: 2px solid #006847;
  }

  .backToDashboard {
    i {
      vertical-align: middle;
      margin-right: 5px;
    }

    text-transform: uppercase;
    float: right;
  }

  .smallPadding {
    padding: 7px;
  }
}
</style>
