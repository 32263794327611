<template>
  <div id="sub-level-navigation" v-if="items.length > 0">
    <template v-if="showGrid">
      <div class="d-none d-md-block">
        <div class="flex-row row mt-2">
          <router-link
            tag="div"
            :to="{ name: 'learningPageContent', params: { module: lpModule.id, id: item.id } }"
            v-for="item in items"
            :key="item.id"
            class="mb-3 pointer"
            :class="{
              'col-4': items.length == 1,
              'col-lg-6':
                ((items.length % 2 === 0 && items.length % 4 !== 0) || items.length < 3 || items.length === 6) &&
                items.length !== 1,
              'col-lg-4 col-md-6':
                (items.length % 3 === 0 ||
                  items.length % 3 === 2 ||
                  (items.length % 3 == 1 && items.length !== 4) ||
                  items.length % 2 === 1) &&
                items.length > 2,
              'col-lg-3 col-md-6': items.length % 4 === 0 || items.length > 3 || items.length === 8
            }"
          >
            <div class="card">
              <div class="imageContainer">
                <img :src="imgSource(item)" />
              </div>
              <div class="card-body lp-body-130">
                <h5 class="font-weight-bold text-left mb-2">{{ item.title }}</h5>
                <tooltip v-if="item.description.length" :desc="item.description"></tooltip>
              </div>
            </div>
          </router-link>
        </div>
      </div>
      <div class="d-block d-md-none d-lg-none d-xl-none">
        <div class="flex-row row">
          <router-link
            tag="div"
            :to="{ name: 'learningPageContent', params: { module: lpModule.id, id: item.id } }"
            v-for="item in items"
            :key="item.id"
            class="mb-3 pointer col-sm-12"
          >
            <div class="card">
              <div class="row">
                <div class="col-6 pr-0">
                  <div class="imageContainer">
                    <img :src="imgSource(item)" />
                  </div>
                </div>
                <div class="card-body col-6 lp-body-130">
                  <h5 class="font-weight-bold text-left mb-2">{{ item.title }}</h5>
                  <tooltip v-if="item.description.length" :desc="item.description"></tooltip>
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </template>
    <template v-if="showSwipe">
      <div class="d-none d-md-block">
        <!-- DESKTOP SWIPE -->
        <div v-if="data.children.length > 1">
          <swiper :options="swiperOption">
            <swiper-slide v-for="item in items" :key="item.id">
              <div class="row">
                <div class="col-md-7">
                  <router-link :to="{ name: 'learningPageContent', params: { module: lpModule.id, id: item.id } }">
                    <div class="imageContainer">
                      <img :alt="item.title" :src="imgSource(item)" />
                    </div>
                  </router-link>
                </div>

                <div class="col-md-5">
                  <router-link :to="{ name: 'learningPageContent', params: { module: lpModule.id, id: item.id } }">
                    <h5 class="font-weight-bold text-left mt-3 mb-5 pointer">{{ item.title }}</h5>

                    <p class="text-left">{{ item.description }}</p>
                  </router-link>
                </div>
              </div>
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </div>
        <div v-else>
          <div class="row">
            <div class="col-md-7">
              <router-link
                :to="{ name: 'learningPageContent', params: { module: lpModule.id, id: data.children[0].id } }"
              >
                <div class="imageContainer">
                  <img :src="imgSource(data.children[0])" />
                </div>
              </router-link>
            </div>

            <div class="col-md-5">
              <router-link
                :to="{ name: 'learningPageContent', params: { module: lpModule.id, id: data.children[0].id } }"
              >
                <h5 class="font-weight-bold text-left mt-3 mb-5 pointer">{{ data.children[0].title }}</h5>
                <p class="text-left">{{ data.children[0].description }}</p>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="d-block d-md-none d-lg-none d-xl-none">
        <div v-if="data.children.length > 1">
          <swiper :options="swiperOption">
            <swiper-slide v-for="item in items" :key="item.id">
              <router-link
                role="button"
                :to="{ name: 'learningPageContent', params: { module: lpModule.id, id: item.id } }"
              >
                <div class="imgContainer">
                  <div class="imageContainer">
                    <img :alt="item.title" :src="imgSource(item)" />
                  </div>
                </div>
              </router-link>

              <div class="p-3">
                <router-link :to="{ name: 'learningPageContent', params: { module: lpModule.id, id: item.id } }">
                  <h5 class="font-weight-bold text-left mt-3 mb-3 pointer">{{ item.title }}</h5>
                </router-link>
                <tooltip v-if="item.description.length" :desc="item.description"></tooltip>
              </div>
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </div>
        <div v-else>
          <router-link :to="{ name: 'learningPageContent', params: { module: lpModule.id, id: data.children[0].id } }">
            <div class="imgContainer">
              <div class="imageContainer">
                <img :alt="data.children[0].title" :src="imgSource(data.children[0])" />
              </div>
            </div>
          </router-link>

          <div class="px-2">
            <router-link
              :to="{ name: 'learningPageContent', params: { module: lpModule.id, id: data.children[0].id } }"
            >
              <h5 class="font-weight-bold text-left mt-3 mb-3 pointer">{{ data.children[0].title }}</h5>
            </router-link>
            <p class="text-left">{{ data.children[0].description }}</p>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Tooltip from '../../components/ui/Tooltip';

export default {
  props: {
    data: {
      type: Object
    },
    items: {
      type: Array
    },
    lpModule: {
      type: Object
    },
    title: {
      type: String
    }
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: 1,
        loop: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }
      }
    };
  },
  created() {
    // FIX IE object fit
    setTimeout(() => {
      var images = document.querySelectorAll('img');
      objectFitImages(images);
    }, 1);
  },
  components: {
    Tooltip
  },
  methods: {
    imgSource(item) {
      if (item.images && item.images.length > 0) {
        let mainImage = item.images.find((image) => image.isMain);
        if (!mainImage) {
          mainImage = item.images[0].url;
        }
        return mainImage.url;
      }
      return require('@lp/images/placeholder.png');
    }
  },
  computed: {
    showGrid() {
      return this.subpagesAppearance === 1;
    },
    showSwipe() {
      return this.subpagesAppearance === 0;
    },
    subpagesAppearance() {
      if (this.data) {
        return this.data.subpagesAppearance;
      }

      return 0;
    }
  }
};
</script>

<style lang="scss">
@import '@shared/styles/_helpers.scss';
@import '@shared/styles/_font-sizes.scss';

// Aspect ratio images for swiper/grid/learn more in learning pages
.imageContainer {
  display: block;
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
  padding: 50% 0 0 0; // Aspect ratio 2:1

  img {
    object-fit: cover;
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

#sub-level-navigation {
  @media (max-width: 576px) {
    h5 {
      font-size: 0.8rem;
    }
  }
  @media (min-width: 576px) {
    .lp-body-130 {
      min-height: 130px;
    }
  }
  a {
    text-decoration: none;
  }
  h4 {
    margin-bottom: 19px;
  }
  .card-body {
    padding: 16px;
  }
  .card-body a h5 {
    @include font-size($header);
  }
  .swiper-container {
    .swiper-pagination {
      left: 0;
      width: 100%;
      position: relative;
      top: 5px;
    }

    @media (min-width: 576px) {
      .swiper-slide {
        border: 1px solid #dddddd;
        border-radius: $general-border-radius;
        box-shadow: 1px 1px 3px 0px #dddddd;
      }
    }
  }
}
</style>
